.header {
  background: #fff !important;
  position: relative;
  height: 80px;
}
.lang_link {
  position: fixed;
  left: 5rem;
  top: 3rem;
  height: 4rem;
}

.aramco-logo {
  /* position: absolute;
  right: 4rem;
  top: 3rem; */
  height: 4rem;
  width: auto;
}

.question {
  font-size: 1.2rem;
  font-weight: normal;
  padding-left: 0;
  /* padding-top: 2rem; */
  /* text-align: justify; */
  color: #00a9e8 !important;
}

h3.main_question {
  color: #131516 !important;
}

@if ($direction== "ltr") .lang_link {
  left: 3rem;
  top: 3rem;
  font-size: 1.5rem;
}

.content-container {
  margin-top: 6rem;
  margin-left: 5%;
  width: 90%;
} @else .lang_link {
  left: 3rem;
  top: 3rem;
  font-size: 1.2rem;
}

.content-container {
  margin-top: 6rem;
  margin-right: 5%;
  width: 90%;
}

.question-area {
  min-height: auto;
}

.validation-error-message {
  margin-right: 13%;
}

h3.main_question {
  padding: 0 1rem 0 0;
}

@endif .question {
  /* padding-top: 1rem; */
}

.question hr {
  background-color: #00843d;
  height: 3px;
}

div.emoji-container-wrapper {
  background-color: rgb(220, 221, 222, 0.3);
  border-radius: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

div.before_emoji-container {
  height: 0;
}

div.emoji-container {
}

ul.emoji_ul {
  /* padding-top: 1rem; */
  margin: 0;
}

ul.emoji_ul li img {
  width: 2.5rem;
  height: 2.5rem;
}

div.feedback_option_container .feedback_option {
  border-radius: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 4rem;
}

.feedback_option_container .feedback_option:nth-of-type(odd) {
  background-color: rgb(0, 170, 226, 0.2);
}

.feedback_option_container .feedback_option:nth-of-type(even) {
  background-color: rgb(131, 191, 65, 0.2);
}
.odd-bg {
  background-color: rgb(131, 191, 65, 0.2) !important;
}
.even-bg {
  background-color: rgb(0, 170, 226, 0.2) !important;
}
h3.main_question.quest-option {
  font-size: 1.3rem;
  padding: 0 !important;
  margin: 0;
}

h3.main_question.quest-option strong {
  font-size: 1rem;
  padding: 0 !important;
  margin: 0;
}
.checkbox_radio_container {
  position: relative;
}
.checkbox_radio_container .wrapper {
  padding-left: 1.5rem;
}

/* .checkbox_radio_container input[type="radio"] {
  display: inline-block;
  .feedback_option .checkbox_radio_container input[type="radio"]
  height: 2.5rem;
} */

.radio-custom {
  opacity: 1;
  position: absolute;
  width: 2rem;
  height: 2rem;
  padding: 2px;
  margin-right: 10px;
}

.radio-custom-label {
  position: relative;
  width: 2rem;
  height: 2rem;
  background: #00a9e8;
}

.radio-custom {
  content: "";
  border: 2px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
}

.radio-custom:checked {
  background: rebeccapurple;
  color: red;
}

.radio-custom:focus {
  outline: 1px solid rgb(0, 170, 226, 0.2);
}

.feedback_option .checkbox_radio_container input[type="radio"] {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}
textarea.rating-value {
  background-color: #dcddde;
}

strong {
  font-weight: bold;
}

.validation-error-message {
  padding: 1rem 0 3rem;
}

.feedback-question-container-outer {
  display: none;
}

#feedback_submit {
  font-size: 1.5rem !important;
  border-radius: 1rem;
}

@media only screen and (min-device-width: 768px) /* and (max-device-width: 1024px) */ /* and (orientation: portrait) */ /* and (-webkit-min-device-pixel-ratio: 1) */ {
  #spnError {
    bottom: -3rem !important;
    height: auto;
    right: 0;
    top: auto;
    padding: 0.5rem;
    font-size: 1.5rem;
  }

  .header {
    margin-top: 2rem;
  }
}

@media only screen and (max-device-width: 767px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .feedback-qa-wrapper .feedback-question-container {
    display: none !important;
  }

  .feedback_option_container .feedback-question-container-outer {
    display: block;
  }

  .answer-area {
    margin-top: 0;
  }

  #spnError {
    bottom: -3rem;
    height: auto;
    right: 0;
    top: auto;
    padding: 0.5rem;
    font-size: 1.1rem;
  }
}

@media only screen and (max-device-width: 465px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .feedback-qa-wrapper .feedback-question-container {
    display: none !important;
  }

  .feedback_option_container .feedback-question-container-outer {
    display: block;
  }

  .lang_link {
    position: fixed;
    left: 2rem;
    top: 3rem;
    height: 2rem;
  }

  .aramco-logo {
    /* position: absolute;
    right: 3rem;
    top: 3rem; */
    height: 2.5rem;
    width: auto;
  }

  ul.emoji_ul {
    /* padding-top: 0.2rem; */
    margin: 0;
  }

  ul.emoji_ul li img {
    width: 2rem;
    height: 2rem;
  }

  .formValidateInline .answer-area {
    padding: 0;
    margin: 0;
  }

  .formValidateInline .answer-area .row-answer-area {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-right: 0.5rem;
  }

  div.feedback_option_container .feedback_option {
    border-radius: 4rem;
    margin-top: 0.1rem;
    margin-bottom: 1rem;
    min-height: 3rem;
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important;
  }

  .mx-3 {
    margin-left: 0.8rem !important;
    margin-right: 0.6rem !important;
  }

  .feedback_option {
    padding-top: 1rem;
  }

  #spnError {
    bottom: -3rem;
    height: auto;
    right: 0;
    top: auto;
    padding: 0.5rem;
    font-size: 1.1rem;
  }
}

@font-face {
  /* font-family: ManifaPro2-2-2-223-SemiBold; */
  /* src: url(./ManifaPro2_2.223-SemiBold.otf); */
}

body,
html {
  font-size: 1rem;
  font-family: ManifaPro2-2-2-223-SemiBold;
  line-height: 1.4;
}

.header {
  background: #fff !important;
}

.lang_link {
  position: fixed;
  left: 5rem;
  top: 3rem;
  height: 4rem;
}

.aramco-logo {
  /* position: absolute;
  right: 4rem;
  top: 3rem; */
  height: 4rem;
  width: auto;
}

.question {
  font-size: 1.2rem;
  font-weight: normal;
  padding-left: 0;
  /* padding-top: 2rem; */
  /* text-align: justify; */
  color: #00a9e8 !important;
}

h3.main_question {
  color: #131516 !important;
}

@if ($direction== "ltr") .lang_link {
  left: 3rem;
  top: 3rem;
  font-size: 1.5rem;
}

.content-container {
  margin-top: 6rem;
  margin-left: 5%;
  width: 90%;
} @else .lang_link {
  left: 3rem;
  top: 3rem;
  font-size: 1.2rem;
}

.content-container {
  margin-top: 6rem;
  margin-right: 5%;
  width: 90%;
}

.question-area {
  min-height: auto;
}

.validation-error-message {
  margin-right: 13%;
}

h3.main_question {
  padding: 0 1rem 0 0;
}

@endif .question {
  /* padding-top: 1rem; */
}

.question hr {
  background-color: #00843d;
  height: 3px;
}

div.emoji-container-wrapper {
  background-color: rgb(220, 221, 222, 0.3);
  border-radius: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

div.before_emoji-container {
  height: 0;
}

div.emoji-container {
}

ul.emoji_ul {
  /* padding-top: 1rem; */
  margin: 0;
}

ul.emoji_ul li img {
  width: 2.5rem;
  height: 2.5rem;
}

div.feedback_option_container .feedback_option {
  border-radius: 4rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 4rem;
}

.feedback_option_container .feedback_option:nth-of-type(odd) {
  background-color: rgb(0, 170, 226, 0.2);
}

.feedback_option_container .feedback_option:nth-of-type(even) {
  background-color: rgb(131, 191, 65, 0.2);
}

h3.main_question.quest-option {
  font-size: 1.3rem;
  padding: 0 !important;
  margin: 0;
}

h3.main_question.quest-option strong {
  font-size: 1rem;
  padding: 0 !important;
  margin: 0;
}

.radio-custom {
  opacity: 1;
  position: absolute;
  width: 2rem;
  height: 2rem;

  padding: 2px;
  margin-right: 10px;
}

.radio-custom-label {
  position: relative;
  width: 2rem;
  height: 2rem;
  background: #00a9e8;
}

.radio-custom {
  content: "";
  border: 2px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 2px;
  margin-right: 10px;
  text-align: center;
}
@media only screen and (min-device-width: 768px) /* and (max-device-width: 1024px) */ /* and (orientation: portrait) */ /* and (-webkit-min-device-pixel-ratio: 1) */ {
  #spnError {
    bottom: -3rem !important;
    height: auto;
    right: 0;
    top: auto;
    padding: 0.5rem;
    font-size: 1.5rem;
  }

  .header {
    margin-top: 2rem;
  }
}

@media only screen and (max-device-width: 767px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .feedback-qa-wrapper .feedback-question-container {
    display: none !important;
  }

  .feedback_option_container .feedback-question-container-outer {
    display: block;
  }

  .answer-area {
    margin-top: 0;
  }

  #spnError {
    bottom: -3rem;
    height: auto;
    right: 0;
    top: auto;
    padding: 0.5rem;
    font-size: 1.1rem;
  }
}

@media only screen and (max-device-width: 465px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .feedback-qa-wrapper .feedback-question-container {
    display: none !important;
  }

  .feedback_option_container .feedback-question-container-outer {
    display: block;
  }

  .lang_link {
    position: fixed;
    left: 2rem;
    top: 3rem;
    height: 2rem;
  }

  .aramco-logo {
    /* position: absolute;
    right: 3rem;
    top: 3rem; */
    height: 2.5rem;
    width: auto;
  }

  ul.emoji_ul {
    /* padding-top: 0.2rem; */
    margin: 0;
  }

  ul.emoji_ul li img {
    width: 2rem;
    height: 2rem;
  }

  .formValidateInline .answer-area {
    padding: 0;
    margin: 0;
  }

  .formValidateInline .answer-area .row-answer-area {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-right: 0.5rem;
  }

  .feedback_option .checkbox_radio_container input[type="radio"] {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
  }

  div.feedback_option_container .feedback_option {
    border-radius: 4rem;
    margin-top: 0.1rem;
    margin-bottom: 1rem;
    min-height: 3rem;
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important;
  }

  .mx-3 {
    margin-left: 0.8rem !important;
    margin-right: 0.6rem !important;
  }

  .feedback_option {
    padding-top: 1rem;
  }

  #spnError {
    bottom: -3rem;
    height: auto;
    right: 0;
    top: auto;
    padding: 0.5rem;
    font-size: 1.1rem;
  }
}
.feedback_option_container .feedback_option:nth-of-type("4") {
  background-color: #fff;
  margin: 0;
  padding: 0;
  display: block !important;
}

.feedback_option_container
  .feedback_option:nth-of-type("4")
  .feedback-qa-wrapper {
  margin: 0;
  padding: 0;
}

.feedback_option_container
  .feedback_option:nth-of-type("4")
  .feedback-answer-options-container {
  margin: 0;
  padding: 0;
}

.feedback_option_container
  .feedback_option:nth-of-type("4")
  .feedback-answer-options-container
  ul
  div.textarea-container {
  margin: 0;
  padding: 0;
}

.feedback_option_container
  .feedback_option:nth-of-type("4")
  .feedback-answer-options-container
  ul
  div.textarea-container
  textarea {
  margin: 0;
  padding: 1rem;
  border-radius: 1rem;
}
.feed-select {
  /* width: 100%; */
  /* height: 40px; */
  border: 1px solid rgb(254 254 254 / 0%);
  font-size: 14px;
  /* padding-left: 15px; */
  outline: none !important;
  color: #000;
  border-radius: 32px;
  -webkit-appearance: none;
  background-color: transparent !important;
}
.feed-small {
  text-align: center !important;
  display: flex !important;
  /* justify-content: center; */
  /* padding-bottom: 17px; */
  margin-top: -10px !important;
}
.feed-text-box {
  width: 100%;
  border: 1px solid #ebebeb;
  /* border-top: none;
  border-left: none;
  border-right: none; */
  height: 40px;
  outline: none;
  border-radius: 20px;
  padding: 0 10px;
}
.feed-text-box::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ebebeb;
}
.feed-text-box::-moz-placeholder {
  /* Firefox 19+ */
  color: #ebebeb;
}
.feed-text-box:-ms-input-placeholder {
  /* IE 10+ */
  color: #ebebeb;
}
.feed-text-box:-moz-placeholder {
  /* Firefox 18- */
  color: #ebebeb;
}
.feed-num-rating label {
  cursor: pointer;
  z-index: 90;
  /* line-height: 1.8em; */
  border-radius: 100%;
  border: 1px solid #ebebeb;
}

/* ------------------------RadioWidgetRating------------------------------ */
.radio-group-feed input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

/* Style the label as a custom radio button */
.radio-group-feed label {
  display: inline-block;
  /*   padding: 10px 20px; */
  width: 40px;
  height: 40px;
  margin: 5px;
  border: 2px solid #ccc;
  border-radius: 100%;
  border: 1px solid #ebebeb;
  cursor: pointer;
  text-align: center;
  align-content: center;
}

/* Add styles when the radio button is selected */
.radio-group-feed input[type="radio"]:checked + label {
  background: rgb(0, 170, 226, 0.6) !important;
  color: #ffffff !important;
}
/* ------------------------RadioWidgetRating------------------------------ */
